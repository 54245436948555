.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 56px;
  width: 100vw;
  height: 100vh;
  background-color: var(--gray50);
  overflow: hidden;
  overflow-x: auto;
}

.contract_detail_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 690px;
  overflow: hidden scroll;
  background-color: var(--gray50);
}

.is_center {
  border-left: 1px solid var(--gray100);
  border-right: 1px solid var(--gray100);
}
